import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AuthSession, SupabaseClient } from '@supabase/supabase-js';
import { Observable, Subject } from 'rxjs';
import { urlJoin } from '@himingle/shared';
import { BaseNgrxProvider, NStatusDB } from '@himingle/ui-shared-ngrx';
import { StatusDB } from '../app/models';
import { ApplicationValues } from '../../models';

export abstract class RenderDocumentProvider extends BaseNgrxProvider {
  statuses$ = new Subject<StatusDB[]>();

  statuses: StatusDB[] = [];
  protected _applicationValues!: ApplicationValues;

  protected _session: AuthSession | null = null;

  constructor(protected platformId: object) {
    super();
  }

  isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  override getNStatuses$(): Observable<NStatusDB[]> {
    return this.statuses$;
  }

  override getStatuses(): NStatusDB[] {
    return this.statuses;
  }

  override getSupabaseClient(): SupabaseClient {
    return this.getSupabase();
  }

  abstract override whoIam(): string;

  abstract getSupabase(): SupabaseClient;

  get applicationValues(): ApplicationValues {
    return this._applicationValues;
  }

  absoluteUrl(pathTail?: string) {
    if (pathTail) {
      return urlJoin(this._applicationValues.mainUrl, pathTail);
    }
    return this._applicationValues.mainUrl;
  }

  finalize() {
    return;
  }

  async getSession(): Promise<AuthSession | null> {
    return Promise.resolve(null);
  }

  async init(
    applicationValues: ApplicationValues,
  ): Promise<{ initialized: boolean; hasSession: boolean }> {
    this._applicationValues = applicationValues;
    return { initialized: true, hasSession: false };
  }

  hasSession(): Promise<boolean> {
    return Promise.resolve(false);
  }

  eraseSession() {
    return;
  }

  async onLogout() {
    this.eraseSession();
    return this.getSupabase()?.auth.signOut();
  }

  get session() {
    this.getSession()?.then(() => {
      return true;
    });
    return this._session;
  }

  mainUrl() {
    return this.absoluteUrl();
  }
}
