"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shared = void 0;
function shared() {
  return 'shared';
}
exports.shared = shared;
