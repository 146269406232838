"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomLoggerFE = void 0;
const log_1 = require("./log");
class CustomLoggerFE extends log_1.CustomLogger {
  constructor(silentValue) {
    super();
    this.silent = false;
    //
    // // for cli and npm levels
    this.error = empty(this, 'error');
    this.warn = empty(this, 'warn');
    this.help = empty(this, 'help');
    this.data = empty(this, 'data');
    this.info = empty(this, 'info');
    this.debug = empty(this, 'debug');
    this.prompt = empty(this, 'prompt');
    this.http = empty(this, 'http');
    this.verbose = empty(this, 'verbose');
    this.input = empty(this, 'input');
    this.silly = empty(this, 'silly');
    //
    // // for syslog levels only
    this.emerg = empty(this, 'emerg');
    this.alert = empty(this, 'alert');
    this.crit = empty(this, 'crit');
    this.warning = empty(this, 'warning');
    this.notice = empty(this, 'notice');
    this.silent = silentValue;
    this.log = logF(this).bind(this);
  }
  //
  isLevelEnabled(level) {
    return false;
  }
  isErrorEnabled() {
    return false;
  }
  isWarnEnabled() {
    return false;
  }
  isInfoEnabled() {
    return false;
  }
  isVerboseEnabled() {
    return false;
  }
  isDebugEnabled() {
    return false;
  }
  isSillyEnabled() {
    return false;
  }
}
exports.CustomLoggerFE = CustomLoggerFE;
const logF = ctx => {
  return (level, messageOrMeta, ...meta) => {
    let entry;
    // Check if level is a LogEntry object
    if (typeof level === 'object' && level.level && level.message) {
      entry = level;
    } else {
      // Prepare log entry based on the provided arguments
      entry = {
        level: level.toString(),
        message: messageOrMeta.toString()
      };
      if (Array.isArray(meta) && meta.length > 0) {
        entry['meta'] = meta;
      } else if (typeof messageOrMeta === 'object') {
        entry['meta'] = [messageOrMeta];
      }
    }
    // Log the entry
    if (!ctx.silent) {
      console.log(`[${entry.level}] ${entry.message}`, ...(entry['meta'] || []));
    }
    return this;
  };
};
const empty = (ctx, e) => {
  return logF(ctx).bind(ctx, e);
};
